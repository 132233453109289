// extracted by mini-css-extract-plugin
export var AuthorAvatarContainer = "Z_c3";
export var AuthorBioContainer = "Z_c1";
export var AuthorContainer = "Z_c0";
export var AuthorGradientBackground = "Z_c2";
export var AuthorInfoContainer = "Z_c4";
export var AuthorInfoDetails = "Z_c5";
export var AuthorInfoName = "Z_c6";
export var AuthorInfoSection = "Z_c7";
export var MuiPopperRoot = "Z_bp";
export var MuiTooltipPopper = "Z_bq";