// extracted by mini-css-extract-plugin
export var actions = "bC_bF";
export var articleMultipleChoiceCTA = "bC_d6";
export var body = "bC_d8";
export var bodyContainer = "bC_d7";
export var illustration = "bC_fb";
export var selected = "bC_ff";
export var subtitle = "bC_fd";
export var textContent = "bC_fc";
export var tile = "bC_d9";
export var wrapper = "bC_br";